import { useSelector } from "react-redux";

import Logo from "../../assets/icons/logo.png";
import downloadOnAppStore from "../../assets/icons/downloadOnAppStore.svg";
import getItOnPlayStore from "../../assets/icons/getItOnPlayStore.svg";
import { APP_STORE_DOWNLOAD_URL, PLAY_STORE_DOWNLOAD_URL } from "../../constants/CommonContants";

const FooterBar = () => {
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);
  return (
    <footer className="flex flex-col gap-10 justify-center items-center w-full p-10 mt-20">
      <img className="w-20 bg-black object-contain" src={Logo} alt="" />
      <p className="text-xs md:text-sm text-white text-opacity-50 font-semibold">
        {currentLanguageTitles?.[0]?.home_meta?.footer_copyright ??
          "Copyright @ Today with God 2024. | Privacy & Policy"}
      </p>
      <div className="flex flex-col items-center w-full gap-5">
        <hr className="h-0.5 bg-white bg-opacity-30 border-none w-6/12" />
        <p className="text-white">
          Download the Today With God app on Google Play and Apple App Store
        </p>
        <div className="flex items-center gap-3">
          <a href={PLAY_STORE_DOWNLOAD_URL} target="_blank" rel="noreferrer">
            <img
              src={getItOnPlayStore}
              alt="Download from Play Store"
              className="rounded-md download-button-shadow"
            />
          </a>
          <a href={APP_STORE_DOWNLOAD_URL} target="_blank" rel="noreferrer">
            <img
              src={downloadOnAppStore}
              alt="Download from App Store"
              className="rounded-md download-button-shadow"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterBar;
