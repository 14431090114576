import jsPDF from "jspdf";
import { useParams, useSearchParams } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { curriculumReadAction } from "../../store/Curriculam/CurriculumAction";

import certificateTemplate from "../../assets/images/certificate_template.jpg";
import { useEffect } from "react";
import axios from "axios";
import { getProfileAction, uploadAssestsAction } from "../../store/Home/HomeAction";
import Toastiy from "../../components/common/Toastiy";
import LoaderSpinner from "../../components/common/LoaderSpinner";

const CertificateDownload = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useSearchParams(true);
  const dispatch = useDispatch();

  const getCertificateTemplate = (title, name) => {
    return (
      <div className="w-[792px] relative">
        <img src={certificateTemplate} alt="certificate template" className="w-full" />
        <div
          className="uppercase text-white font-medium absolute top-[205px] left-[300px] w-[392px]"
          style={{ wordSpacing: "4px" }}
        >
          <p className="w-full text-center">{title} Curriculum</p>
        </div>
        <div
          className="uppercase text-3xl font-bold absolute top-[310px] left-[220px] w-[552px]"
          style={{ wordSpacing: "5px" }}
        >
          <p className="w-full text-center">{name}</p>
        </div>
        <p className="text-center text-[#14303C] text-[0.95rem] leading-none w-[60%] font-medium absolute top-[380px] right-[50px]">
          For the completion of the Today With God discipleship curriculum covering {title}. The
          above recipient is hereby certified to lead groups and utilize the {title} impact tools of
          Today With God in a leadership fashion and to additionally train others in the same.
        </p>
      </div>
    );
  };

  const generatePDFDocument = (userProfileDetails, title) => {
    var doc = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 613],
      hotfixes: ["px_scaling"],
    });
    doc.html(
      ReactDOMServer.renderToStaticMarkup(
        getCertificateTemplate(
          title,
          `${userProfileDetails?.firstName} ${userProfileDetails?.lastName}` ?? ""
        )
      ),
      {
        callback: async function (doc) {
          const pdfBlob = doc.output("blob");

          const tempFile = new File([pdfBlob], new Date().getTime() + ".pdf", {
            type: "application/pdf",
            lastModified: new Date().getTime(),
            name: title + "_Certificate" + new Date().getTime(),
          });

          dispatch(
            uploadAssestsAction({
              apiPayloadRequest: { file: tempFile },
              callback: (res) => {
                console.log(res?.url);
                window.open(res?.url, "_self");
              },
            })
          );

          setLoading(false);
        },
        margin: [0, 0, 0, 0],
        width: 792,
        windowWidth: 792,
      }
    );
  };

  const getCertificateDetails = () => {
    setLoading(true);
    console.log("token", searchParams.get("code"));
    if (searchParams.has("code")) {
      localStorage.setItem("token", searchParams.get("code"));
    }
    const promiseArray = [];
    promiseArray.push(
      new Promise((resolve, reject) => {
        dispatch(
          curriculumReadAction({
            apiPayloadRequest: { _id: id },
            callback: (res) => {
              resolve(res);
            },
          })
        );
      })
    );
    promiseArray.push(
      new Promise((resolve, reject) => {
        dispatch(
          getProfileAction({
            callback: (res) => {
              resolve(res);
            },
          })
        );
      })
    );

    Promise.allSettled(promiseArray)
      .then((result) => {
        console.log("promise Result", result);
        generatePDFDocument(
          result?.[1]?.value?.data?.[0],
          result?.[0]?.value?.data?.[0]?.curriculum_name
        );
      })
      .catch((error) => {
        Toastiy("Something went wrong, please try again later");
      });
  };

  useEffect(() => {
    getCertificateDetails();
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-black overflow-y-auto w-full gap-10 min-h-[100vh] relative overflow-auto">
      {loading && (
        <div className="flex flex-col items-center text-white">
          <LoaderSpinner className="mb-3 w-8 h-8" />
          <p className="text-2xl">Loading your Certificate</p>
        </div>
      )}
    </div>
  );
};

export default CertificateDownload;
