import { useEffect, useState } from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import FooterBar from "../../components/common/FooterBar";
import TopVideo from "../../components/home/TopVideo";

import { useDispatch, useSelector } from "react-redux";
import { getPromiseOfTheDayAction, readPromiseAction } from "../../store/Home/HomeAction";
import { useAuth0 } from "@auth0/auth0-react";
import CoverEffectCarousel from "../../components/home/CoverEffectCarousel";
import ReactModal from "react-modal";
import { MdCancel } from "react-icons/md";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

const Devotions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { languagePreferred, currentLanguageTitles } = useSelector(
    (state) => state.languageReducer.data
  );

  const initialTabs = ["Today's Devotional"];

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValues, setTabsValues] = useState(initialTabs);
  const [swiper, setSwiper] = useState(null);

  const [currentPromiseOfTheDay, setCurrentPromiseOfTheDay] = useState();
  const [promises, setPromises] = useState([]);
  const [checkToken, setCheckToken] = useState(localStorage.getItem("token"));
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [twgReadWordsModal, setTwgReadWordsModal] = useState(false);

  useEffect(() => {
    if (id && promises?.length > 0 && swiper) {
      const findIndex = promises.findIndex((d) => d?._id === id);
      const find = promises.find((d) => d?._id === id);

      if (findIndex > -1) {
        swiper?.slideTo(findIndex + 2, 300, false);
        setCurrentPromiseOfTheDay(find);
      }

      if (searchParams.has("video") && searchParams.get("video") === "true") {
        navigate("/video-player/", {
          state: {
            url: currentPromiseOfTheDay?.videos?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
            backTo: "TWG",
            returnTo: location?.pathname,
          },
        });
      }
    }
  }, [id, promises, swiper, searchParams]);

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE_URL,
      });

      setCheckToken(token);
      return token;
    } catch (error) {
      console.error("Error getting access token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };

    // Listen for the `resize` event and call `updateHeight` function
    window.addEventListener("resize", updateHeight);

    // Cleanup: Remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    dispatch(
      readPromiseAction({
        callback: (res) => {
          if (res?.type === 1) {
            const promiseList = res?.data?.map((item) => ({
              ...item,
              _id: item?._id,
              title: item?.twg_title,
              name: item?.twg_title,
              hearGodsWord: item?.hear_gods_word,
              readGodsWord: item?.read_gods_word,
              subtitle: item?.read_gods_word,
              image: item?.video_Tumbnails?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
              audio: item?.audios?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
              video: item?.videos?.filter((i) => i?.type_of_url === "1")[0]?.url_link,
              type: "promise",
            }));
            promiseList.sort((a, b) => new Date(b.date) - new Date(a.date));
            setPromises(promiseList);
          }
        },
      })
    );
  }, [languagePreferred]);

  useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        if (checkToken === null) {
          const token = await getToken();
          localStorage.setItem("token", token);
        }
      }
    };

    try {
      fetchToken();
    } catch (err) {
      console.error(err);
    }
  }, [isAuthenticated, checkToken]);

  return (
    <>
      <div className="flex">
        <div className="flex w-full md:flex-row flex-col overflow-hidden">
          <Navbar active={"Home"} />
          <div className="md:ml-[104px] flex flex-col w-full">
            <HeaderBar
              popCancle={true}
              tabValues={tabValues}
              active={0}
              absolute={false}
              getCurrentLanguageTitles={(titles) => {
                if (titles?.home_meta) {
                  const requiredLangData = titles?.home_meta;
                  setTabsValues([requiredLangData.todaywithgod_lbl]);
                } else {
                  setTabsValues(initialTabs);
                }
              }}
            />
            <div className="flex flex-col bg-black h-full overflow-y-auto w-full">
              <>
                {promises?.length > 0 && (
                  <CoverEffectCarousel
                    height={height}
                    width={width}
                    data={promises}
                    onItemChange={(index) => {
                      console.log("find swiper index", index);
                      setCurrentPromiseOfTheDay(promises[index]);
                    }}
                    copySwiper={(ev) => {
                      setSwiper(ev);
                    }}
                    className="!pt-0"
                    backTo={"TWG"}
                  />
                )}
                <TopVideo
                  background={
                    currentPromiseOfTheDay?.video_Tumbnails?.filter(
                      (i) => i?.type_of_url === "1"
                    )[0]?.url_link
                  }
                  url={
                    currentPromiseOfTheDay?.videos?.filter((i) => i?.type_of_url === "1")[0]
                      ?.url_link
                  }
                />
                <p className="px-6 pt-6 text-lg font-semibold text-white">
                  {currentLanguageTitles?.[0]?.promises_meta?.hear_godsword_lbl ?? "Hear Gods Word"}
                </p>
                <div className="m-6 flex flex-col md:flex-row">
                  {currentPromiseOfTheDay?.hwg_Image?.filter((i) => i?.type_of_url === "1")[0]
                    ?.url_link ? (
                    <img
                      className="md:w-[30%] h-[35vh] object-cover object-top rounded-lg mb-3 md:mb-0 bg-yellowish/80"
                      src={
                        currentPromiseOfTheDay?.hwg_Image?.filter((i) => i?.type_of_url === "1")[0]
                          ?.url_link
                      }
                      alt="thumbnail not found"
                    />
                  ) : (
                    <div className="md:w-[30%] h-[35vh] object-cover object-top rounded-lg mb-3 md:mb-0 bg-yellowish/80"></div>
                  )}

                  <div className="flex flex-col gap-3 justify-between md:px-6 md:w-[70%]">
                    <p className="text-base md:text-xl text-white opacity-50">
                      {currentPromiseOfTheDay?.hear_gods_word}
                    </p>
                    <div className="flex flex-col gap-4 pt-6 mb-6">
                      <p className="text-yellow-400">
                        {currentLanguageTitles?.[0]?.promises_meta?.hear_godsword_lbl ??
                          "Hear Gods Word"}
                      </p>
                      <audio controls className="w-full">
                        <source
                          src={
                            currentPromiseOfTheDay?.audios?.filter((i) => i?.type_of_url === "1")[0]
                              ?.url_link
                          }
                        />
                      </audio>
                    </div>
                  </div>
                </div>
                <p className="px-6 pt-6 text-lg font-semibold text-white">
                  {currentLanguageTitles?.[0]?.promises_meta?.read_godsword_lbl ?? "Read Gods Word"}
                </p>
                <div className="px-6">
                  <div
                    className="mt-6 relative bg-no-repeat w-full h-[80vh] rounded-xl bg overflow-hidden bg-cover bg-top bg-yellowish/60"
                    style={{
                      backgroundImage: `url('${
                        currentPromiseOfTheDay?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]
                          ?.url_link
                      }')`,
                    }}
                  >
                    <div className="absolute w-full h-full bg-gradient-to-r from-black to-transparent"></div>
                    <div
                      className=" flex w-full h-full justify-center items-center p-[5%] 
                      "
                    >
                      <p className="text-white text-base md:text-3xl opacity-60">
                        {currentPromiseOfTheDay?.read_gods_word?.length < 150 ? (
                          currentPromiseOfTheDay?.read_gods_word
                        ) : (
                          <>
                            {currentPromiseOfTheDay?.read_gods_word?.substring(0, 300) + ".."}{" "}
                            <span
                              className="text-yellowish cursor-pointer"
                              onClick={() => {
                                setTwgReadWordsModal(true);
                              }}
                            >
                              {currentLanguageTitles?.[0]?.home_meta?.readnow ?? "more"}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </>
              <ReactModal
                isOpen={twgReadWordsModal}
                className={
                  "flex justify-center items-center h-[100vh] z-[100] overflow-hidden bg-black bg-opacity-90 relative "
                }
                onRequestClose={() => setTwgReadWordsModal(false)}
              >
                <div className="w-full h-screen bg-blackish flex justify-center items-center flex-col gap-6 relative">
                  <button
                    className="absolute top-5 right-5"
                    onClick={() => setTwgReadWordsModal(false)}
                  >
                    <MdCancel color="#EFBC51" size={30} />
                  </button>
                  <div
                    className="mt-6 relative bg-no-repeat w-9/12 h-[80vh] rounded-xl overflow-y-auto no-scrollbar bg-cover bg-top bg-yellowish/60"
                    style={{
                      backgroundImage: `url('${
                        currentPromiseOfTheDay?.bg_Images?.filter((i) => i?.type_of_url === "1")[0]
                          ?.url_link
                      }')`,
                    }}
                  >
                    <div className="absolute w-full h-full bg-gradient-to-r from-black to-transparent"></div>
                    <div
                      className=" flex w-full h-full justify-center items-center p-[5%] 
                      "
                    >
                      <p className="text-white text-base md:text-3xl opacity-60">
                        {currentPromiseOfTheDay?.read_gods_word}
                      </p>
                    </div>
                  </div>
                </div>
              </ReactModal>
              <FooterBar />
            </div>
          </div>
        </div>
      </div>
      {/* <MobileAppDownload /> */}
    </>
  );
};

export default Devotions;
